import VueRouter from 'vue-router';

const Home = () => import('_views/Home/Home.vue');
const Search = () => import('_views/Search/Search.vue');
const NotFound = () => import('_views/NotFound/NotFound.vue');
const Display = () => import('_views/Display/Display.vue');
const RawView = () => import('_views/RawView/RawView.vue');
const About  = () => import('_views/About/About.vue');
const AboutTimeline = () => import('_views/About/Components/timeline.vue');
const AboutDocuments = () => import('_views/About/Components/documents.vue');
const AboutNews = () => import('_views/About/Components/news.vue');
const AboutSobre = () => import('_views/About/Components/sobre.vue');
const AboutTeam = () => import('_views/About/Components/team.vue');
const AboutTutorial = () => import('_views/About/Components/tutorial.vue');
const AboutAtt = () => import('_views/About/Components/atualizacao.vue');
const Historico = () => import('_views/Historico/Historico.vue');
const Constituicao = () => import('_views/Constituicao/Constituicao.vue');

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: Home
    },
    {
        path: '/busca',
        name: 'BuscaVazia',
        component: Search,
    },
    {
        path: '/busca/:q',
        name: 'Busca',
        component: Search,
    },
    {
        path: '/lei-complementar',
        name: 'Leis Complementares',
        component: Search,
    },
    {
        path: '/lei-ordinaria',
        name: 'Leis Ordinárias',
        component: Search,
    },
    {
        path: '/ementario',
        name: 'Ementário',
        component: Search,
    },
    {
        path: '/decreto-numerado',
        name: 'Decretos Numerados',
        component: Search,
    },
    {
        path: '/ato-atualizado',
        name: 'Atos Atualizados',
        component: Search,
    },
    {
        path: '/emenda-constitucional',
        name: 'Emendas Constitucionais',
        component: Search,
    },
    {
        path: '/visualizar/:tipoato/:numero',
        name: 'Visualização Ato',
        component: Display,
    },
    {
        path: '/html/:tipoato/:numero',
        name: 'Visualização HTML',
        component: RawView,
    },
    {
        path: '/sobre',
        name: 'Sobre',
        children: [
            {
                path: '/sobre',
                name: 'SobreProjeto',
                component: AboutSobre,
            },
            {
                path: '/sobre/timeline',
                name: 'SobreTimeline',
                component: AboutTimeline,
            },
            {
                path: '/sobre/documentos',
                name: 'SobreDocuments',
                component: AboutDocuments,
            },
            {
                path: '/sobre/novidades',
                name: 'SobreNews',
                component: AboutNews,
            },
            {
                path: '/sobre/equipe',
                name: 'SobreTeam',
                component: AboutTeam,
            },
            {
                path: '/sobre/tutoriais',
                name: 'SobreTutorial',
                component: AboutTutorial,
            },
            {
                path: '/sobre/atualizacoes',
                name: 'SobreAtt',
                component: AboutAtt,
            },
        ],
        component: About,
    },
    {   path: '*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/visualizar/:tipoato/:numero/historico',
        name: 'Historico',
        component: Historico
    },
    {
        path: '/constituicao',
        name: 'Constituição',
        component: Constituicao
    }
]

const router = new VueRouter({
  mode: 'history',
    base: '',
    routes
});

export default router;
