<template>
  <div v-if="!loading" style="height: 100%">
    <div
      id="app"
      class="container-fluid px-0"
      style="height: 100%"
      v-if="status"
    >
      <router-view></router-view>
    </div>

    <div id="siteOFF" class="container-fluid vh-100" v-if="!status || errored">
      <Manutencao></Manutencao>
    </div>

    <div vw class="enabled" id="vlibras">
      <div vw-access-button class="active"></div>
      <div vw-plugin-wrapper>
        <div class="vw-plugin-top-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Manutencao from "./views/Maintenance/Maintenance.vue";
export default {
  name: "app",
  data() {
    return {
      status: null,
      loading: true,
      errored: false,
      errorMessage: null,
    };
  },
  components: {
    Manutencao,
  },
  beforeCreate: () => {
    document.body.className = "home";
  },
  mounted() {
    this.loading = false;
    this.status = true;
  },
};
</script>

<style>
[data-theme="darkMode"] #vlibras {
  filter: invert(100%) hue-rotate(180deg);
}
.v-application--wrap {
  min-height: 0px !important;
  margin-bottom: 15px;
}
</style>
