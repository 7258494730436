<template>
    <div class="d-flex justify-content-around flex-wrap" id="contentMaintenance">
        <div class="flex-grow-1" id="txtMaintenance">
            <div class="flex-column" id="main">
                <h1 id="h1Maintenance">O LegisOn<br/>está em<br/>manutenção</h1>
                <hr id="hrMaintenance"/>
                <p id="pMaintenance">Volte mais tarde, em breve o site será restabelecido.</p>
            </div>
        </div>
        <div id="imgMaintenance">
            <img src="../../assets/imgs/manutencao.svg" alt="Site em manutenção" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Maintenance',
        title: 'Estamos em manutenção - LegisOn',
    }
</script>

<style>
    @import './Maintenance.css';
</style>