import Vue from 'vue';
import moment from 'moment';
import VueGtag from 'vue-gtag';

// Router
import VueRouter from 'vue-router';
import VueSweetAlert2 from 'vue-sweetalert2';
import router from './router/Routes.js';
Vue.use(VueRouter);
Vue.use(VueSweetAlert2);
Vue.use(require('vue-cookies'));

// Mixins
import titleMixin from '@/shared/mixins/titleMixin';
Vue.mixin(titleMixin);

// Vuetify
import vuetify from './plugins/vuetify';

// Bootstrap
import {
    LayoutPlugin,
    NavPlugin,
    NavbarPlugin,
    CollapsePlugin,
    FormPlugin,
    FormGroupPlugin,
    FormInputPlugin,
    SpinnerPlugin,
} from 'bootstrap-vue';
import './shared/styles/main.css';
import 'sweetalert2/dist/sweetalert2.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";
//import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
//Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(NavPlugin); 
Vue.use(NavbarPlugin);   
Vue.use(CollapsePlugin);   
Vue.use(FormPlugin);   
Vue.use(FormGroupPlugin);   
Vue.use(FormInputPlugin);   
Vue.use(SpinnerPlugin);
Vue.prototype.moment = moment
// Vue.prototype.$apiURL = "http://localhost:44397" // Local
Vue.prototype.$apiURL = "https://legison.pge.se.gov.br"; // Produção
// Vue.prototype.$apiURL = "http://pge-srv-031:5004"; // Homologação 
//Vue.prototype.$apiURL = "http://legison-api"; // Homologação
// HTTP
import { http } from "@/http-common";
Vue.prototype.$http = http;
Vue.prototype.$http.get("/TipoAto/Get")
    .then(response => {
        Vue.prototype.$enumTipoAto = response.data.reduce(function (result, item) {
            let key = item[Object.keys(item)[0]];
            let value = item[Object.keys(item)[1]].replace(/\s/g, "-")                // remove os espaços
                                                  .toLowerCase()                      // converte em minusculo
                                                  .normalize("NFD")                   // disassocia acentos de letras
                                                  .replace(/[\u0300-\u036f]/g, "");   // remove os acentos

            result[key] = value;
            return result;
        }, {});
    })
    .catch(error => {
        throw error;
    })
    
Vue.use(VueGtag, {
    config: { id: "G-ZQ9V79HECF" }
}, router);

Vue.$cookies.config('9h');

//
Vue.config.productionTip = false;
import App from './App.vue';


new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
