import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0D8C1A',
                secondary: '#3D3B3B',
            }
        }
    },
    icons: {
        iconfont: 'mdiSvg'
    }
})